import React from 'react';
import {Spinner} from 'reactstrap';

const Loader = ({size = 'lg', color = 'primary', className}) => {
  return (
    <div className={'flex-fill d-flex justify-content-center align-items-center ' + className}>
      <Spinner size={size} color={color} />
    </div>
  );
};

export default React.memo(Loader, () => true);
