import React from 'react';
import {motion} from 'framer-motion';
import Icons from '../../../assets/svgs/icons';

const Radio = ({
  radioClass,
  textClass,
  className,
  text,
  onClick,
  children,
  animation = {whileTap: {scale: 0.94}},
  icons = ['radio_off', 'radio_on'],
  colors = ['#757575', '#0033a0'],
  disabled = false,
  check = false,
}) => {
  return (
    <motion.div
      {...animation}
      onClick={onClick}
      className={'c_radio ' + className}
      style={{pointerEvents: !disabled ? 'auto' : 'none'}}>
      <div className={radioClass}>{<Icons name={icons[+check]} size={'1rem'} color={colors[+check]} />}</div>
      {children ?? <span className={textClass}>{text}</span>}
    </motion.div>
  );
};

export default Radio;
